<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app>
      <div class="w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <p class="mt-3">Licença até: {{ licenca }}</p>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container pa-3">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class=" w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2021 <a href="#" class="text-decoration-none">CbSystem</a></span>
        </div>
      </div>
    </v-footer>

    <v-dialog v-model="dialogLicenca" persistent max-width="500px">
      <v-card class="text-center">
        <v-card-text class="text-h5" v-if="!licencaEncerrada">
          <b>Falta {{ dias }} para sua licença inspirar</b>
        </v-card-text>
        <v-card-text class="text-h5" v-else>
          <b>Sua licença inspirou favor renovar seu acesso ao sistema</b>
        </v-card-text>

        <v-card-text v-if="!licencaEncerrada">
          deseja renovar sua licença ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="dialogLicenca = false" v-if="!licencaEncerrada">Depois</v-btn>
          <v-btn color="success" outlined @click="pegarUrlPagamento()">Renovar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>

</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
      licenca: '',
      dialogLicenca: false,
      dias: 0,
      licencaEncerrada: false
    }
  },

  methods: {
    dataAtualFormatada(data) {
      var data = data,
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    },

    pegarUrlPagamento() {
      this.$http.get("preferenciasPagamento?idEmpresa=" + localStorage.getItem("id"))
        .then((res) => {
          window.open(res.data.url);
        })
        .catch((error) => {
          console.log(error);
        });

    }
  },

  beforeMount() {
    this.$http.get("empresaId?id=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
      .then((res) => {
        var licenca = res.data.dados.licenca
        licenca = new Date(licenca)
        this.licenca = this.dataAtualFormatada(licenca)

        var timeDiff = licenca.getTime() - new Date();
        var dias = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (dias <= 10) {
          this.dias = dias
          this.dialogLicenca = true
        }

        if (dias <= 0) {
          this.licencaEncerrada = true
        }
      })
      .catch((error) => {
        console.log(error);
      });

  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
