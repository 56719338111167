import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import api from './configs/api'
import VueTheMask from 'vue-the-mask'

Vue.prototype.$http = api
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  VueTheMask,
  render: h => h(App),
}).$mount('#app')
