import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
  },


  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/sistema/Login.vue'),
    meta: {
      layout: 'blank',
    }
  },

  {
    path: '/esqueciSenha',
    name: 'esqueciSenha',
    component: () => import('@/views/sistema/esqueciSenha.vue'),
    meta: {
      layout: 'blank',
    }
  },

  {
    path: '/registrar',
    name: 'registrar',
    component: () => import('@/views/sistema/Registrar.vue'),
    meta: {
      layout: 'blank',
    }
  },


  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/sistema/dashboard/Dashboard.vue'),
  },

  {
    path: '/configuracoes',
    name: 'configuracoes',
    component: () => import('@/views/sistema/account-settings/AccountSettings.vue'),
  },

  {
    path: '/paciente',
    name: 'paciente',
    component: () => import('@/views/sistema/paciente/Paciente.vue'),
  },

  
  {
    path: '/profissional',
    name: 'profissional',
    component: () => import('@/views/sistema/profissional/Profissional.vue'),
  },

  {
    path: '/calendario',
    name: 'calendario',
    component: () => import('@/views/sistema/calendario/calendario.vue'),
  },
  
  {
    path: '/financeiro',
    name: 'financeiro',
    component: () => import('@/views/sistema/financeiro/financeiro.vue'),
  },

  {
    path: '/paciente/:id',
    name: 'pacienteVisualizar',
    component: () => import('@/views/sistema/paciente/PacienteVisualizar.vue'),
  },

  {
    path: '/imprimirAtestado/:id',
    name: 'imprimirAtestado',
    component: () => import('@/views/sistema/paciente/imprimirAtestado.vue'),
    meta: {
      layout: 'blank',
    }
  },

  {
    path: '/imprimirReceituario/:id',
    name: 'imprimirReceituario',
    component: () => import('@/views/sistema/paciente/imprimirReceituario.vue'),
    meta: {
      layout: 'blank',
    }
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
