<template>
  <v-fade-transition mode="out-in">
    <v-icon :key="$vuetify.theme.dark" @click="salvarTema()">
      {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },

  methods: {
    salvarTema: function () {
      var tema = localStorage.getItem("theme")
      if (tema == undefined || tema == null) {
        localStorage.setItem("theme", false);
      } else {
        if (tema == "true") {
          localStorage.setItem("theme", false);
        } else {
          localStorage.setItem("theme", true);
        }
      }

      if (localStorage.getItem("theme") == "true") {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }

      console.log(this.$vuetify.theme.dark)
    }
  },

  beforeMount() {
    if (localStorage.getItem("theme") == "true") {
      this.$vuetify.theme.dark = true
    } else {
      this.$vuetify.theme.dark = false
    }
  },
}
</script>

<style>
</style>
